$text-size-map: (
  xxs: 0.5rem,
  xs : 0.75rem,
  sm: 0.875rem,
  md: 1rem,
  lg: 1.3rem,
  xl: 1.5rem,
);

$text-weight-map: (
  400: 400,
  500: 500,
  600: 600,
  700: 700,
);

$text-align: (center, left, right, start, end);

$padding: (
  0: 0,
  1: 1rem,
  8: 0.5rem,
  24: 3rem, 
);
$margin: (
  0: 0,
  auto: auto,
  1: 1rem,
  4 : 0.25rem,
  8 : 0.5rem, 
  16 : 1rem,
  24 : 1.5rem,
  40 : 2.5rem,
  46 : 2.875rem,
  30p : 30%,
);

$spacer: (
  s: 0.25rem,
  m: 0.5rem,
  l: 1rem,
  xl: 1.5rem,
  xxl: 2rem,
);

$size: (
  max: max-content,
  min: min-content,
  auto: auto,
  100: 100%,
  75: 75%,
  50: 50%,
);

$hw: (
  auto: auto,
  100: 100%,
  90: 90%,
  80: 80%,
  70: 70%,
  60: 60%,
  50: 50%,
  40: 40%,
  30: 30%,
  20: 20%,
  10: 10%,
  5: 5%,
  15: 15%,
  25: 25%,
  35: 35%,
  45: 45%,
  55: 55%,
  65: 65%,
  75: 75%,
  85: 85%,
  91: 91%,
  92: 92%,
  93: 93%,
  94: 94%,
  96: 96%,
  97: 97%,
  98: 98%,
  99: 99%,
  81: 81%,
  82: 82%,
  83: 83%,
  84: 84%,
  86: 86%,
  87: 87%,
  88: 88%,
  89: 89%,
  71: 71%,
  72: 72%,
  73: 73%,
  74: 74%,
  76: 76%,
  77: 77%,
  78: 78%,
  79: 79%,
);
