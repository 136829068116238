.form-layout-container{
  width: 100%;
  height: 100%;
  padding: var(--gutter-xs);
  position: relative;
}
.dynamic-form-fields-container{
  height: 90%;
}
.ant-picker-date-panel{
  width: 100%;
}
.file-upload-btn-container{
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 5% 0;
}
.form-layout{
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    opacity: 0.5;
  }
  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
    color: var(--white-color);
    border-color: var(--primary-color);
    background: var(--primary-color);
    text-shadow: none;
    box-shadow: none;
    opacity: 0.5;
  }
  .form-sub-section-name{
    color: var(--text-color-secondary);
    font-weight: 500;
    font-size: var(--font-size-heading-l5);
    margin-bottom: var(--gutter);
  }
  .subSection2{
    font-size: var(--font-size-lg);
    color: var(--text-color-secondary);
  }
  .form-divider{
    border-bottom: 1px solid var(--border-color);
    margin-top: var(--gutter);
    margin-bottom: var(--gutter);
  }
  .form-title-row{
    .form-back-btn{
      font-size: var(--font-size-heading-l2);
    }
    .form-title{
      font-size: var(--font-size-heading-l4);
      font-weight: 500;
    }
    margin-bottom: calc(var(--gutter-xs)*2);
  }
  .form-header{
    position: relative;
    max-height: 10%;
    margin-bottom: calc(var(--gutter-xs)*2);
  }
  .form-body{
    height: 90%;
    position: relative;
    width: 100%;
  }
  .form-fields-container{
    position: relative;
    height: 85%;
    overflow-y: auto !important;
  }
  .form-fields-container::-webkit-scrollbar{
    display: none;
  }
  .form-footer, .edit-program-form-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    .form-previous-btn{
      font-weight: 600;
      font-size: var(--font-size-base);
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
    .form-submit-btn{
      font-weight: 600;
      font-size: var(--font-size-base);
    }
  }
  .form-footer{
    height: 10%;
  }
  .edit-program-form-footer{
    height: 0%;
  }
  .form-btns-row{
    border-top: 1px solid var(--border-color);
    padding-top: 3%;
    position: relative;
  }
}
.ant-modal-body{
  .form-layout-container{
    padding: 0 !important;
  }
  .form-layout{
    padding: 0 !important;
  }
}
.dynamic-form-file-preview-container{
  height: 10vh;
  border: 1px solid var(--border-color-split);
  width: 100%;
  background-color: var(--neutral-color);
}
textarea{
  resize: none;
}
.verticalCheckboxList{
  label{
      width: 100%;
  }
}

@media screen and (max-width: 768px) {

  .form-title-row {
    flex-flow: inherit !important;
  }
  
  .form-title {
    margin-left: 1rem;
  }
}