.communication-dashboard-container{
    padding: var(--gutter-xs);
    height: 100%;
    overflow: hidden;
}
.communication-dashboard-table-container {
    display: block;
    height: 100%;
    background-color: white;
    padding-left: 1rem;
    .dashboard-datatable{
      height: 90%;
    }
  }
  .ant-picker {
    width: 100%;
  }