.lab-dashboard-container {
  padding: var(--gutter-xs);
  height: 100%;
}
.lab-dashboard-table-container {
  display: block;
  padding: var(--gutter-xs);
  height: 100%;
}

.lab-order-table-action-cta-styling {
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: "Poppins";
  font-size: var(--font-size-base);
  font-weight: 600;
  line-height: 1.3125rem;
  margin-right: 1rem;
}
.lab-dashboard-table-data-row{
  height: 85%;
}

.program-details-section-name{
  font-size: var(--font-size-heading-l5);
  font-weight: 500;
}