.doctor-consent-page-container {
  overflow: auto;
  height: 100vh;
  padding: 3rem;
  background-color: var(--white-color);
  font-family: "Open Sans", sans-serif;
}

.doctor-consent-submit-button {
  background-color: #2e5cc2;
  color: var(--white-color);
  float: right;
}

.doctor-consent-form-date-container {
  text-decoration: underline;
}

.doctor-consent-footer-heading-styling {
  margin: 0;
}

.doctor-consent-resend-otp-button-active, .doctor-consent-resend-otp-button-inactive {
  outline: none;
  border: none;
  padding-left: 0;

  span {
    text-decoration: underline;
    color: #007bff;
  }

  /* Style for the button on hover, focus, and when disabled */
  &:hover,
  &:focus,
  &:disabled,
  &:active {
    background-color: var(--white-color) !important;
  }
}

.doctor-consent-resend-otp-button-inactive {
  span {
    color: var(--info-color)
  }
}

.doctor-consent-otp-modal-container {
  .ant-modal-header {
    padding: 1rem !important;
  }

  .ant-modal-body {
    padding: 2rem !important;
  }
}


.doctor-consent-description-heading {
  margin-bottom: 0;
}