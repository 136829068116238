.mark-pv-form {
  margin-top: 5%;
  margin-bottom: 5%;
  .form-layout-container,
  .form-layout {
    padding: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item .ant-form-item-label {
    padding-bottom: 0.25rem;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: var(--font-size-base);
    font-weight: 400;
  }
  .ant-radio-wrapper,
  .ant-radio,
  .ant-radio-inner {
    font-size: var(--font-size-base) !important;
    font-weight: 400;
  }
  .ant-form-item-control-input {
    min-height: 1px !important;
  }
  .ant-btn-text {
    padding: 0;
    color: var(--primary-color);
    text-decoration: underline;
    font-size: var(--font-size-base);
    font-weight: 400;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    height: fit-content;
  }
}
.pv-patient-details-container {
  margin-bottom: 5%;
}
.pv-update-form-btn-container,
.pv-after-update-form-btn-container {
  width: 100%;
  .ant-btn-lg {
    width: 100%;
    max-height: 52px;
    height: 10vw;
    padding: 0;
    font-weight: 600;
    font-size: var(--font-size-base);
  }
  .ant-btn-default {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]{
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center;
}

.login-otp-resend-btn {
    margin-bottom: 0.25rem !important;
    color: var(--primary-color);
}