
 .ant-dropdown{
   width: 15vw;
   height: fit-content;
   max-height: 19vw;
 }
.profile-menu-container{
  border-radius: 0.5rem;
  background-color: var(--white-color);
  padding-top: var(--gutter);
  padding-bottom: var(--gutter-xs);
  .profile-menu-name{
    margin-top: var(--gutter);
    font-weight: 600;
    font-size: var(--font-size-heading-l4);
  }
  .profile-menu-user-role{
    color: var(--text-color-ternary);
    margin-bottom: var(--gutter-xs);
    font-size: var(--font-size-sm);
  }
  .profile-menu-switch-role-heading{
    font-size: var(--font-size-lg);
    font-weight: 600;
  }
  .profile-menu-roles-list{
    box-shadow: none;
    padding-left: 2%;
  }
  .profile-menu-divider{
    margin-top: var(--gutter-xs);
    margin-bottom: var(--gutter-xs);
    border-top: 1px solid var(--border-color);
    width: 100%;
  }
  .profile-menu-btn{
    font-weight: 600;
  }
}