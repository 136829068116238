.pdf-container{
    width: 100%;
    height: 90%;
    text-align: center;
    margin-top: var(--gutter-xs);
}
.pdf-preview-object{
    height: 100%;
    width: 60%;
}
