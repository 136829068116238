.pv-title{
    font-size: var(--font-size-heading-l5);
    text-align: left;
    min-height: calc(var(--font-size-heading-l5) * 1.5);
    font-weight: 600;
}
.pv-sub-title{
  font-size: var(--font-size-sm);
  text-align: left;
  min-height: calc(var(--font-size-sm) * 1.5);
  font-weight: 400;
}
.validate-pv-order-container{
    margin-top: 5%;
    width: 100%;
    .ant-form-item-label label{
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
    }
    .ant-btn-lg{
      width: 100%;
      max-height: 52px;
      height: 10vw;
      padding: 0;
      font-weight: 600;
      font-size: var(--font-size-base);
    }
    .ant-btn-default{
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
}