.patient-address-sub-type{
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0;
}
.patient-address-proof-download{
  position: absolute;
  top: 0.5rem;
  right: 0;
  cursor: pointer;
}