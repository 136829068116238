.pv-dashboard-container {
  padding: var(--gutter-xs);
}

.pv-content-container {
  padding: var(--gutter-xs);
}

.pv-dashboard-table-container {
  display: block;
  padding: var(--gutter-xs);
  height: 85vh;
}

.pv-dashboard-cta-container {
  margin-left: 1rem;
}

.ant-picker-ok {
  button {
    padding: 0 0.5rem;
  }
}

.ant-picker-ranges {
  display: flex;
}
