.doctor-consent-form-input-field {
    width: 25%;
}

.doctor-consent-form-heading {
    text-align: left;
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: var(--font-size-heading-l4);
    margin-bottom: 0;
}

.doctor-consent-tnc-heading {
    color: var(--text-color) !important;
}

.doctor-consent-field-styling {
    font-weight: 600;
}