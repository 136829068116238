.applicationLayout {
  .ant-layout-content {
    height: 100%;
  }
  .ant-layout-header {
    border-bottom: 1px solid var(--border-color);
  }
  .dls-secondary-btn {
    font-weight: 600;
    font-size: var(--font-size-base);
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.side-bar-desktop-view {
  display: block;
}

.module-menu-mobile-view {
  display: none;
}

@media screen and (max-width: 768px) {
  .side-bar-desktop-view {
    display: none;
  }

  .module-menu-mobile-view {
    display: block;
  }

  .ant-dropdown {
    width: 60%;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    top: 1rem;
  }

  .ant-dropdown-menu-submenu-title {
    padding-right: 9.5rem;
  }

  .ant-dropdown-menu-submenu-vertical {
    display: flex;
    gap: 1rem;
  }

  .anticon {
    margin-right: 1rem;
  }
}
.ant-select-item-option-content {
    overflow: auto !important;
    white-space: break-spaces !important;
}