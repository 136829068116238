.phleboLayout{
    height: 100%;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    background-color: var(--white-color);
    .ant-layout-header{
        padding: 0 12px !important;
        border-bottom: 1px solid #0000001A;
    }
}
.mobileProfileMenu{
    // margin-left: -70%;
    max-width: 200px;
}
.phlebo-portal-header-text{
    font-size: var(--font-size-heading-l4);
    font-weight: 500;
    text-align: center;
}
.phlebo-content-holder{
    margin-top: 2%;
    padding: 0 12px !important;
    overflow-y: auto;
}
@media (min-width: 1200px) {
    .phleboLayout{
        padding: 0 35% 0 35%;
    }
}