.dynamic-form-steps{
  height: 10%;
  margin-bottom: 1%;
  .ant-steps-item-title{
    font-size: 0.75rem;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
    background-color: var(--success-color);
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    border: 3px solid var(--success-color);
    background-color: var(--white-color);
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background-color: var(--success-color);
  }
  .ant-steps-item-title{
    font-weight: 500;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
   color: var(--success-color);
  }
}
.ant-steps-item-title{
  width: 90px !important;
}