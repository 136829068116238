.ant-modal-close{
    display: none;
}
.ant-modal-footer{
    border: none;
    display: flex;
    button{
        font-weight: 600;
        width: 50%;
    }
    .ant-btn-default{
        border-color: var(--primary-color);
        color: var(--primary-color);
    }
}
.ant-modal-body{
    .modal-title{
        color: var(--text-color);
        font-size: var(--font-size-heading-l5);
        font-weight: 500;
        margin-bottom: 5%;
    }
    .optional{
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
    }
}