.primary-bg {
  background-color: var(--primary-color) !important;
}
.primary-btn, .primary-btn:hover{
  background-color: var(--primary-btn-color) !important;
  border-color: var(--primary-btn-color) !important;
  color : var(--white-color) !important;
}
.white-bg{
  background-color: var(--white-color);
}