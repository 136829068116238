.ant-layout {
  height: 100%;
  width: 100%;
}
.ant-descriptions-item-label{
  text-transform: capitalize;
}
.secondary-action-btn{
  border-color: var(--primary-color);
  color: var(--primary-color);
}