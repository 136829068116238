@import "./assets/scss/typography";
@import "./assets/scss/spacing";
@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets//scss/colors";


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh !important;
  overflow: hidden !important;
  width: 100vw !important;
  max-height: 100vh !important;
  max-width: 100vw !important;
}
