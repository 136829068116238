.ant-pagination{
    position: relative;
    margin-top: var(--gutter-xs);
    margin-bottom: var(--gutter-xs);
}
.ant-pagination-options{
    position: absolute;
    right: 0;
}
.ant-table-filter-trigger{
    color: var(--text-color-secondary) !important;
    font-weight: 400;
    font-size: var(--font-size-base);
}
.ant-table-filter-column{
    justify-content: flex-start;
}
.ant-table-column-title{
    flex: inherit;
}
.ant-table-filter-dropdown{
    min-width: 250px;
}
.dashboard-search-popup{
    border-radius: 0.5rem;
    padding: var(--gutter-xs);
}
.dashboard-search-btn{
    width: 100%;
    font-weight: 600;
}
.dashboard-search-discard{
    color: var(--primary-color);
    border-color: var(--primary-color);
}
.dashboard-datatable{
    height: 95%;
    overflow: auto;
    position: relative;
    width: 100%;
    .dashboard-search-popup {
        .ant-input:placeholder-shown{
            text-transform: capitalize !important;
        }
    }
    .ant-table-cell{
        font-weight: 400;
    }
    .ant-table-thead{
        height: 6.7vh;
        position: sticky;
        top: 0px;
        z-index: 2;
        text-transform: capitalize;
        .ant-table-cell{
            font-weight: 600;
        }
    }
    .filtered-column{
        color: var(--primary-color);
    }
    a{
        color: var(--text-color);
        text-decoration: underline;
        text-decoration-color: var(--text-color);
        font-weight: 500;
    }
}

.custom-filter-container {
    padding: var(--gutter);
}

.custom-range-picker-class {
    .ant-picker-input > input {
        font-size: calc(var(--gutter-xs)*0.625);
    }
}
