.export-btn-desktop-view {
  display: block;
  float: right;
}

.export-btn-mobile-view {
  display: none;
}

@media (max-width: 768px) {
  .export-btn-desktop-view {
    display: none;
  }

  .export-btn-mobile-view {
    display: block;
    float: right;
  }
}
