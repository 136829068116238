.loader-full-page {
  height: 100vh;
  display: grid;
  place-items: center;
}

.loader-masked {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.1);
  top: 0;
  left: 0;
}

.semi-transparent-full-page{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255,255,255,0.5);
}

.opaque-full-page{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255,255,255);
}