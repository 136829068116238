.place-center {
  display: grid;
  place-items: center;
  align-content: center;
}

.error-text-color{
  color: var(--error-color);
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.w-100 {
  width: 100%;
}

.w-max-content {
  width: max-content;
}

.w-auto {
  width: auto;
}

.h-100 {
  height: 100%;
}

.h-max-content {
  height: max-content;
}

.h-auto {
  height: auto;
}

.gap-25 {
  gap: 0.25rem;
}

.i-color {
  color: red;
}

.no-border{
  border: none;
}

.custom-secondary-btn {
  font-weight: 600;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.cursor-pointer{
  cursor: pointer !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.click-not-allowed {
  cursor: not-allowed !important;
  pointer-events: none;
}

.ant-input[disabled]{
  color: var(--text-color-secondary);
}

.elipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.elipsis:hover{
  text-overflow: clip;
  white-space: normal;
  // word-break: break-all;
}

.capitalize{
  text-transform: capitalize;
}

.hide{
  display: none;
}

.overflowAuto {
  overflow: auto;
}