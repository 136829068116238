.order-status-tag{
  border-width: 2px;
  border-radius: 2rem;
}
.pds-success{
 
}
.pds-processing{
 
}
.pds-error{
 
}
.pds-warning{
 
}