.patient-interaction-form-container {
  height: 100%;
  overflow: auto;
  width: 50%;
  margin: auto;
}

.patient-interaction-form-title {
  color: var(--black-85);
  font-size: var(--font-size-heading-l4);
  font-family: "Poppins";
  font-weight: 500;
  line-height: var(--line-height-heading-l4);
}

.patient-interaction-form-page-container {
  height: 100%;
  .loader-masked {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    z-index: 4;
  }
}

.patient-interaction-form-btn-container {
  display: flex;
  justify-content: space-evenly;
}

.ant-select-selection-search {
  right: 2.5rem !important;
}

@media (max-width: 768px) {
  .patient-interaction-form-container {
    height: 100%;
    overflow: auto;
    width: 100%;
    margin: 0;

    .form-fields-container {
      height: 40vh !important;
    }
  }

  .default-text-mobile-view {
    white-space: normal
  }
}

.form-footer-mobile {
  display: flex;
  height: 10%;
  width: 100%;
  justify-content: space-evenly;
  gap: calc(var(--gutter-xs) / 2);
  margin-top: var(--gutter-xs);
}

.form-layout-container {
  width: 100%;
  height: 100%;
  padding: var(--gutter-xs);
  position: relative;
}

.form-layout {
  height: 100%;
  width: 100%;
  padding: var(--gutter-xs);
  position: relative;

  .form-body {
    height: 90%;
    position: relative;
    width: 100%;
  }
  .form-fields-container {
    position: relative;
    height: 85%;
    overflow-y: auto !important;
  }
  .form-fields-container::-webkit-scrollbar {
    display: none;
  }
  .form-footer {
    position: absolute;
    bottom: 0;
    height: 10%;
    width: 100%;
    .form-previous-btn {
      font-weight: 600;
      font-size: var(--font-size-base);
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
    .form-submit-btn {
      font-weight: 600;
      font-size: var(--font-size-base);
    }
  }
}