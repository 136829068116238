.communication-details-page-container {
  height: var(--content-max-height);
  overflow: hidden;
  padding: var(--gutter-xs) var(--gutter) var(--gutter-xs) var(--gutter);
  width: 100%;
  @media (max-width: 767px) {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.communication-container {
  padding: var(--gutter-xs);
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  word-wrap: break-word;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.communication-details-page-heading {
  color: var(--black-85);
  font-family: "Poppins";
  font-size: var(--font-size-heading-l4);
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 0;
  padding: 0 1rem;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: inherit;
  }
}
.communication-heading-text-section {
  display: flex;
  gap: var(--gutter-xs);
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.communication-details-page-back-button {
  font-size: var(--font-size-heading-l3);
} 
.text-bold{
  font-weight: 600;
}
.communication-details-dataBlock{
 padding: .5rem 1rem;
}
.communication-details-value{
  padding: 0 1rem;
}
