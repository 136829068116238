.document-upload-btn-container{
  display: flex;
  border: 1px solid #000;
  padding: 1rem;
  position: relative;
  align-items: center;
  width: 100%;
  .document-upload-icon{
    position: absolute;
    right: 1rem;
  }
}
.document-upload-allowed{
  cursor: pointer;
}
.document-upload-not-allowed{
  cursor: not-allowed;
}