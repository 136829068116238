.sidebar-container {
  height: 100%;
  width: fit-content;
  border-right: 1px solid var(--border-color);
  max-width: var(--side-menu-max-width);
  padding: 1%;
  position: relative;
  overflow: hidden;
  .mainLeftMenu{
    overflow-y: auto;
    height: 85%;
  }
  .sidebar-trigger-custom{
    background-color: var(--text-color);
    color: var(--white-color);
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    font-weight: 700;
  }
  .sidebar-greetings-section{
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .sidebar-greeting-text{
    font-size: var(--font-size-lg);
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  .sidebar-greeting-username{
    font-size: var(--font-size-lg);
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .sidebar-greeting-userrole{
    font-size: var(--font-size-lg);
    font-weight: 500;
    border-radius: 0.5rem;
    background-color: var(--secondary-label-color);
    padding: 0.25rem 0.5rem;
    border: none;
  }
  .sidebar-greetings-section-collapsed{
    visibility: hidden;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title{
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: 700;
    border-radius: 0.5rem;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border: none;
  }
  
  .ant-menu-submenu-selected .ant-menu-item-selected{
    background-color: var(--white-color);
    color: var(--primary-color);
    font-weight: 700;
    a, a:hover{
      color: var(--primary-color);
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    font-weight: 700;
    border-radius: 0.5rem;
  }
}